<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Members</h4>
      <div class="add-app-member mb-2">
        <form @submit.prevent="addUser">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Enter user's email</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  v-model="new_user.email"
                  required
                />
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Role</label>
                <select class="form-control" required v-model="new_user.role">
                  <option value="maintainer">Maintainer</option>
                  <option value="developer">Developer</option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label class="d-block" style="visibility: hidden">.</label>
                <button class="btn btn-primary btn-sm">
                  <i class="lni-plus mr-2"></i> Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="alert alert-info alert-with-border mb-5">
        Add a user to grant them access to this app.
      </div>
      <h4>Users with access to this app</h4>
      <ul class="app-members-list">
        <li v-for="(user, i) in users" :key="`member-${i}`">
          <div class="title">
            <img :src="user.image" :alt="user.image" />
            <span class="name">{{ user.name }}</span>
          </div>
          <div class="role">
            <select
              class="form-control"
              v-model="user.role"
              v-if="user.role != 'owner'"
              @change="changeRole(user)"
            >
              <option value="maintainer">Maintainer</option>
              <option value="developer">Developer</option>
            </select>
            <span v-if="user.role == 'owner'">{{ user.role }}</span>
          </div>
          <div class="actions text-right">
            <a
              href="#"
              @click.prevent="deleteUser(user)"
              class="btn btn-danger btn-sm"
              v-if="user.role != 'owner'"
              ><i class="lni-trash"></i
            ></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      users: [],
      new_user: {
        email: "",
        role: "maintainer",
      },
    };
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')
      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/members`)
        .then(response => {
          this.users = response.data;
          this.$loader.stop()
        });
    },

    changeRole(user) {
      let data = {
        user_id: user.id,
        role: user.role,
      };

      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/member-role`, data).then(response => {
        this.users = response.data;
        this.$loader.stop()
      });
    },

    addUser() {
      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/members`, this.new_user).then((response) => {
        this.new_user.email = "";
        this.users = response.data;
        this.$loader.stop()
      });
    },

    deleteUser(user) {
      let data = {
        user_id: user.id
      };
      
      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/delete-member`, data).then(response => {
        this.users = response.data;
        this.$loader.stop()
      });
    },
  },
};
</script>
